import { api } from '.';
import { PLATFORM_DEALS_RESOURCE, SYNC_RESOURCE } from '@/services/keys';

export const getResources = (platformId, dealId) => ({
  [PLATFORM_DEALS_RESOURCE]: platformId,
  [SYNC_RESOURCE]: dealId,
});

/**
 * @param {string} platformId
 * @param {string} dealId
 * @returns {Promise.<object>}
 */
export function updatePlatformDealSync(platformId, dealId) {
  const partialUrl = api.createUrl(getResources(platformId, dealId));
  return api.update(partialUrl);
}
