/// <reference path="../../../../entities/dashboardSSP/DealComment.js" />

import { api } from '.';
import { PLATFORM_DEALS_RESOURCE, COMMENTS_RESOURCE } from '@/services/keys';

export const getResources = platformsDealId => ({
  [PLATFORM_DEALS_RESOURCE]: platformsDealId,
  [COMMENTS_RESOURCE]: undefined,
});

/**
 *
 * @param {string} platformsDealId
 * @param {DealComment} dealsComment
 * @returns {Promise.<string>}
 */
export async function createDealComment(platformsDealId, dealsComment) {
  const partialUrl = api.createUrl(getResources(platformsDealId));
  const {
    data: {
      dealId: { comment },
    },
  } = await api.create(partialUrl, dealsComment.payload());
  return comment.id;
}
