/// <reference path="../../../../entities/dashboardSSP/DealComment.js" />

import { api } from '.';
import { PLATFORM_DEALS_RESOURCE, COMMENTS_RESOURCE } from '@/services/keys';

export const getResources = (platformsDealId, commentId) => ({
  [PLATFORM_DEALS_RESOURCE]: platformsDealId,
  [COMMENTS_RESOURCE]: commentId,
});

/**
 *
 * @param {string} platformsDealId
 * @param {DealComment} dealsComment
 * @returns {Promise.<string>}
 */
export async function deleteDealComment(platformsDealId, dealsComment) {
  const partialUrl = api.createUrl(getResources(platformsDealId, dealsComment.id));
  const {
    data: {
      deal: { comment },
    },
  } = await api.delete(partialUrl, dealsComment.payload());
  return comment.id;
}
