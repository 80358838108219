/// <reference path="../../../../entities/dashboardSSP/Deal.js" />

import { api } from '.';
import { PLATFORM_DEALS_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import dashboardSSPEntityParser from '@/entities/dashboardSSP/DashboardSSPEntityParser';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

export const getResources = platformDealsId => ({
  [PLATFORM_DEALS_RESOURCE]: platformDealsId,
});

/**
 *
 * @param {string} dealsId
 * @return {Promise<{data:PlatformDeals,,meta:EndpointMeta}>}
 */
export async function getPlatformDealById(dealsId) {
  const partialUrl = api.createUrl(getResources(dealsId));
  const params = new QueryParamsBuilder();

  params.addInclude('platforms', 'comments', 'author');
  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(dashboardSSPEntityParser.parse(data), data.meta).build();
}
