import { createDealComment } from './createDealComment';
import { deleteDealComment } from './deleteDealComment';
import { getPlatformDealById } from './getPlatformDealById';
import { getPlatformDealByNameOrId } from './getPlatformDealByNameOrId';
import { getPlatformDeals } from './getPlatformDeals';
import { updateDealComment } from './updateDealComment';
import { updatePlatformDealSync } from './updatePlatformDealSync';
import { getNotLinkedDeals } from './getNotLinkedDeals';

import { dashboardSSP } from '..';

export const api = dashboardSSP;

export {
  createDealComment,
  deleteDealComment,
  getPlatformDealById,
  getPlatformDealByNameOrId,
  getPlatformDeals,
  updateDealComment,
  updatePlatformDealSync,
  getNotLinkedDeals,
};
