import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { getPlatformDeals } from './getPlatformDeals';
import { NOT_LINKED } from '@/model/modules/dashboardSSP/platformDeal/LinkStatus';

/**
 * @param {String} activeClient
 * @param {QueryParamsBuilder} params
 * @return {Promise.<{data:DealType[],meta:import('@/model/shared/ResponseBuilder').EndpointMeta}>}
 */
export async function getNotLinkedDeals(activeClient, params = new QueryParamsBuilder()) {
  params.addFilter('link.status', NOT_LINKED.name);
  params.addFilter('client.id', activeClient);
  const { meta } = await getPlatformDeals(params);
  return { data: { value: meta.totalResults || 0 } };
}
